import React from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { MetaInfo } from "src/types/meta";
import { WorkProjectGrid } from "src/components/work/project-grid";
import { WorkFeaturedProjects } from "src/components/work/featured-projects";
import { Router, useMatch } from "@reach/router";

export interface WorkProps {
	data: {
		page: {
			main: {
				slug: {
					current: string;
				};
				title: string;
				featuredProjects: any[];
			};
			meta: {
				metaTitle?: string;
				metaDescription?: string;
				openImage?: any;
				twitterImage?: any;
			};
		};
		projects: any[];
		tags: any[];
	};
	location: any;
}

const WorkTemplate = ({ location, data }: WorkProps) => {
	const { main, meta } = data.page;
	const { tags } = data;
	const { projects } = data;

	// set fallback meta tags from content
	const metaInfo: MetaInfo = {
		metaTitle: meta?.metaTitle || main.title,
		metaDescription: meta?.metaDescription,
		openImage: meta?.openImage,
		twitterImage: meta?.twitterImage || meta?.openImage,
	};

	const matchWorkIndex = useMatch("/work");
	const matchTaggedWorkIndex = useMatch("/work/tagged/*");

	return (
		<RevealOnMount location={location}>
			<SEO
				metaInfo={metaInfo}
				pagePath={location?.pathname}
				slug={main.slug.current}
			/>
			{matchWorkIndex && (
				<WorkFeaturedProjects projects={main.featuredProjects} />
			)}
			<Router primary={false}>
				<WorkProjectGrid
					default
					location={location}
					projects={projects}
					tags={tags}
				/>
				<WorkProjectGrid
					path={`/work/tagged/*activeTag`}
					location={location}
					projects={projects}
					tags={tags}
				/>
			</Router>
		</RevealOnMount>
	);
};

export default WorkTemplate;
