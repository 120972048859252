import React, { useState, useEffect, useCallback, useRef } from "react";
import cx from "classnames";
import { pure } from "recompose";
import { Image } from "src/components/image";
import { Link } from "gatsby";
import { ArrowLeft, ArrowRight, Dots } from "src/components/slider-ui";
import { useAutoplay } from "src/hooks/useAutoplay";
import { useHover } from "src/hooks/useHover";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import * as styles from "./featured-projects.module.css";

const Slide = ({ data, active }: { data: any; active: boolean }) => {
	const { main } = data.projectRef.content;

	const hoverRef = useRef(null);
	const isHover = useHover(hoverRef);

	const [activeHoverImageIndex, setActiveHoverImageIndex] = useState<number>(0);

	const cycleHoverImages = useCallback(() => {
		setActiveHoverImageIndex(
			activeHoverImageIndex === data.hoverImages?.length - 1
				? 0
				: activeHoverImageIndex + 1
		);
	}, [activeHoverImageIndex, setActiveHoverImageIndex]);

	const { play, stop } = useAutoplay(cycleHoverImages, 750);

	useEffect(() => {
		isHover ? play() : stop();
	}, [play, stop, isHover]);

	const [linkState, setLinkState] = useState({});
	useEffect(() => {
		if (location) {
			setLinkState({ prevPath: location.pathname });
		}
	}, []);

	return (
		<div
			className={cx(
				"keen-slider__slide",
				styles.slide,
				active && styles.active
			)}
			ref={hoverRef}
		>
			<Link to={`/work/${main.slug.current}`} state={linkState}>
				<h4 className={cx("body")}>{main.title}</h4>
				<div className={styles.slideSquare}>
					<div className={styles.slideSquareInner}>
						<Image
							className={styles.slideImage}
							gatsbyImageClassName="y"
							alt={main.title}
							imageId={data.mainImage.asset._id}
						/>
						<div className={cx("x y", styles.slideOverlay)}>
							{data.hoverImages &&
								data.hoverImages.map((image: any, index: number) => {
									return (
										<div
											key={image._key}
											className={cx(
												styles.slideHoverImageContainer,
												activeHoverImageIndex === index ? styles.active : ""
											)}
										>
											<Image
												className={cx(styles.slideHoverImage)}
												loading="eager"
												gatsbyImageClassName="x y"
												alt={main.title}
												imageId={image.asset._id}
											/>
										</div>
									);
								})}
							{data.hoverCopy && (
								<p className={cx("caption", styles.slideOverlayCopy)}>
									{data.hoverCopy}
								</p>
							)}
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export const WorkFeaturedProjects = ({ projects }: { projects: any }) => {
	const [currentSlide, setCurrentSlide] = React.useState(0);

	const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
		slidesPerView: 3.5,
		mode: "snap",
		spacing: 0,
		centered: true,
		loop: true,
		initial: 0,
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		},
		breakpoints: {
			"(max-width: 767px)": {
				slidesPerView: 1,
			},
			"(min-width: 768px) and (max-width: 1080px)": {
				slidesPerView: 2,
			},
		},
	});

	return (
		<div className={styles.featuredProjects}>
			<div ref={sliderRef} className={cx("keen-slider", styles.slider)}>
				{projects.map((project: any, index: number) => (
					<Slide
						key={project._key}
						data={project}
						active={currentSlide === index}
					/>
				))}
			</div>
			{slider && (
				<>
					<ArrowLeft onClick={(e) => e.stopPropagation() || slider.prev()} />
					<ArrowRight onClick={(e) => e.stopPropagation() || slider.next()} />
				</>
			)}
			{slider && <Dots slider={slider} currentSlide={currentSlide} />}
		</div>
	);
};

export const PureWorkFeaturedProjects = pure(WorkFeaturedProjects);
