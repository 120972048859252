import React, { useEffect } from "react";
import { graphql } from "gatsby";
import WorkTemplate from "src/templates/work";
import { useSiteContext, useSetActiveTags } from "src/context/siteContext";

export const query = graphql`
	query TaggedWorkQuery {
		page: sanityWork {
			content: _rawContent(resolveReferences: { maxDepth: 10 })
		}
		tags: allSanityProjectTag {
			edges {
				node {
					_id
					slug {
						current
					}
					title
				}
			}
		}
		projects: allSanityProject(sort: { fields: order, order: ASC }) {
			edges {
				node {
					_id
					content {
						main {
							slug {
								current
							}
							title
							indexThumbnail {
								asset {
									_id
								}
							}
							tags {
								tags {
									title
									slug {
										current
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

const WorkPage = (props: any) => {
	const { location } = props;

	return (
		<WorkTemplate
			location={location}
			data={{
				page: props.data.page.content,
				tags: props.data.tags.edges,
				projects: props.data.projects.edges,
			}}
		/>
	);
};

export default WorkPage;
