// extracted by mini-css-extract-plugin
export var featuredProjects = "featured-projects-module--featuredProjects--GurzI";
export var slider = "featured-projects-module--slider--1Yxb5";
export var slide = "featured-projects-module--slide--2_Mtu";
export var active = "featured-projects-module--active--1JFo2";
export var slideSquare = "featured-projects-module--slideSquare--jVuE3";
export var slideSquareInner = "featured-projects-module--slideSquareInner--3Hidq";
export var slideImage = "featured-projects-module--slideImage--3Z0JX";
export var slideOverlay = "featured-projects-module--slideOverlay--1AhXi";
export var slideOverlayCopy = "featured-projects-module--slideOverlayCopy--3ebrW";
export var slideHoverImageContainer = "featured-projects-module--slideHoverImageContainer--1LjV5";
export var slideHoverImage = "featured-projects-module--slideHoverImage--1ropf";