import { useState, useEffect, RefObject } from "react";

export const useHover = (elementRef: RefObject): boolean => {
	const [value, setValue] = useState<boolean>(false);

	const handleMouseEnter = () => setValue(true);
	const handleMouseLeave = () => setValue(false);

	useEffect(() => {
		const node = elementRef?.current;

		if (node) {
			node.addEventListener("mouseenter", handleMouseEnter);
			node.addEventListener("mouseleave", handleMouseLeave);

			return () => {
				node.removeEventListener("mouseenter", handleMouseEnter);
				node.removeEventListener("mouseleave", handleMouseLeave);
			};
		}
	}, [elementRef]);

	return value;
};
