import React, { useEffect, useState } from "react";
import cx from "classnames";
import { pure } from "recompose";
import { Image } from "src/components/image";
import { Link } from "gatsby";
import {
	useSiteContext,
	useSetActiveTags,
	useToggleFilter,
} from "src/context/siteContext";

import * as styles from "./project-grid.module.css";

interface Tag {
	title: string | undefined;
	slug: {
		current: string;
	};
}

const ProjectCard = ({ data }: { data: any }) => {
	const [linkState, setLinkState] = useState({});
	useEffect(() => {
		if (location) {
			setLinkState({ prevPath: location.pathname });
		}
	}, []);
	return (
		<div className={styles.projectCard}>
			<Link
				className={cx("block", styles.projectCardLink)}
				to={`/work/${data.slug.current}`}
				state={linkState}
			>
				<div className={styles.projectCardImageContainer}>
					<Image
						className={styles.projectCardImage}
						alt={data.title}
						imageId={data.indexThumbnail.asset._id}
						style={{ height: `100%` }}
					/>
				</div>
				<h4 className={cx("label", styles.projectCardTitle)}>{data.title}</h4>
			</Link>
		</div>
	);
};

const PureProjectCard = pure(ProjectCard);

export const WorkProjectGrid = ({
	projects,
	tags,
	activeTag,
}: {
	projects: any[];
	tags: any[];
	activeTag?: string;
}) => {
	const { activeTags }: { activeTags: Tag[] } = useSiteContext();
	const setActiveTags = useSetActiveTags();

	useEffect(() => {
		if (!activeTag) {
			return;
		}
		const activeTagNode =
			tags && tags.find((tag) => tag.node.slug.current === activeTag);

		if (activeTagNode) {
			setActiveTags([activeTagNode.node]);
		}
	}, [tags, activeTag]);

	const activeTagSlugs = activeTags.map((tag: Tag) => tag.slug.current);

	const activeProjects =
		activeTags && activeTags.length
			? projects.filter((project: any) => {
					// collect all project tag slugs
					const projectTags = project.node.content.main.tags?.tags?.map(
						(tag: any) => tag.slug.current
					);

					// compare with active tag slugs
					if (projectTags?.length) {
						return activeTagSlugs.some((tag) => projectTags.indexOf(tag) >= 0);
					}
			  })
			: projects;

	return (
		<>
			<p className={cx("body", styles.resultsMessage)}>
				{activeTags.length ? (
					<>
						{activeProjects.length} results for <em>{activeTags[0].title}</em>.{" "}
						<Link type="internal" to="/work" className="underline">
							Clear.
						</Link>
					</>
				) : (
					<>All Projects</>
				)}
			</p>
			<div className={styles.grid}>
				{activeProjects &&
					activeProjects.map((project: any) => (
						<PureProjectCard
							key={project.node._id}
							data={project.node.content.main}
						/>
					))}
			</div>
		</>
	);
};

// ProjectCard.whyDidYouRender = true;
